/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
import 'zone.js';

/* (window as any).process = {
  env: { DEBUG: undefined },
};
(window as any).global.util = (window as any).global.util || require("util").util; 
*/
(window as any).process = {
    env: { DEBUG: undefined},
};